import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { motion, useAnimationControls } from "framer-motion"
import { navigate } from "gatsby"

export const AnimatedUnderline: React.FC<{ text: string; url: string }> = ({
  text,
  url,
}) => {
  const controls = useAnimationControls()
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  return (
    <Box
      as={motion.div}
      onHoverStart={() =>
        controls.start({ width: "100%", transition: { duration: 0.5 } })
      }
      onHoverEnd={() => controls.start({ width: 0 })}
      w={`fit-content`}
    >
      <Text
        as={motion.p}
        variants={item}
        color={`white`}
        cursor={`pointer`}
        fontSize={{ base: 16, lg: 20 }}
        fontWeight={`bold`}
        onClick={() => navigate(url)}
      >
        {text}
      </Text>
      <Box
        as={motion.div}
        initial={{ width: 0 }}
        animate={controls}
        borderBottom={`2px`}
        borderColor={`white`}
      />
    </Box>
  )
}
