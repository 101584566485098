import React from "react"
import { Flex } from "@chakra-ui/react"
import { AnimatePresence } from "framer-motion"
import { ClickableDot } from "./clickable-dot"
import { ActiveDot } from "./active-dot"

export const PaginateDot: React.FC<any> = ({
  index,
  imageIndex,
  setCurrentIndex,
}) => {
  return (
    <Flex
      key={index}
      justifyContent={`center`}
      alignItems={`center`}
      p={0}
      w={5}
      h={5}
    >
      <AnimatePresence>
        {index === imageIndex ? (
          <ActiveDot />
        ) : (
          <ClickableDot index={index} setCurrentIndex={setCurrentIndex} />
        )}
      </AnimatePresence>
    </Flex>
  )
}
