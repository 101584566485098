import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Heading, Stack, Text, Spacer } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { AnimatedScrollToExplore } from "../components/animated-scroll-to-explore"
import { AnimatedStay } from "../components/animated-stay"
import { AnimatedPurposeImages } from "../components/animated-purpose-images"
import { AnimeContainer } from "../components/anime-container"
import { AnimeHeading } from "../components/anime-heading"
import { AnimeImage } from "../components/anime-image"
import { AnimeText } from "../components/anime-text"
import { AnimatedUnderlineButton } from "../components/animated-under-line-button"
import { AnimatedNews } from "../components/animated-news"

import { AnimatePresence, useAnimationControls } from "framer-motion"
import { ImageSlidesServices } from "../components/slides/image-slides-services"
import { ImageSlidesProperties } from "../components/slides/image-slides-properties"
import { MenuContent } from "../components/menu/menu-content"
import { MenuButton } from "../components/menu/menu-button"
import { CloseButton } from "../components/menu/close-button"

import Seo from "../components/seo"
import Layout from "../components/layout"
import TopVideo from "../movies/top.mp4"

const TopNewPage: React.FC<any> = ({ data }) => {
  const exploreRef = useRef(null)
  const [drawer, setDrawer] = useState<boolean>(false)
  const closeButton = useAnimationControls()
  const menuButton = useAnimationControls()

  const images = data.allContentfulSiteImages.edges.map(({ node }: any) => ({
    image: node.image?.url,
  }))
  const services = data.allContentfulServices.edges.map(({ node }: any) => ({
    src: node.featuredPhoto?.file?.url,
    title: node.name,
    text: node.description.description,
    url: `/services/${node.slug}`,
  }))
  const properties = data.allContentfulProperties.edges.map(
    ({ node }: any) => ({
      src: node.images[0]?.file?.url,
      title: node.propertyName,
      text: node.comment.comment,
      url: `/properties/${node.slug}`,
    })
  )

  return (
    <Layout>
      <Seo title="Home" />
      <Stack position={`relative`} p={0} spacing={0}>
        <Box bg={`blackAlpha.400`} h={`100vh`} w={`100%`} position={`absolute`}>
          <AnimatePresence>{drawer && <MenuContent />}</AnimatePresence>
          <MenuButton
            buttonAnimation={menuButton}
            closeButton={closeButton}
            setDrawer={setDrawer}
          />
          <CloseButton
            buttonAnimation={closeButton}
            openButton={menuButton}
            setDrawer={setDrawer}
          />
          <Heading
            textAlign={`center`}
            pt={8}
            fontSize={{ base: 28, lg: 42 }}
            textTransform={`uppercase`}
            letterSpacing={2}
          >
            Saifarm
          </Heading>
          <Text
            textAlign={`center`}
            fontWeight={`bold`}
            letterSpacing={2}
            fontSize={{ base: 12, lg: 16 }}
            textTransform={`uppercase`}
          >
            Minami-Izu
          </Text>
          <AnimatedScrollToExplore ref={exploreRef}>
            <Text fontSize={{ base: 14, lg: 16 }} color={`white`} mb={4}>
              Scroll to Explore
            </Text>
            <Box borderLeft={`1px`} borderColor={`white`} w={1} h={16} />
          </AnimatedScrollToExplore>
        </Box>

        <Box
          as={`video`}
          muted
          autoPlay
          loop
          playsInline
          src={TopVideo}
          h={`100vh`}
          w={`100vw`}
          objectFit="cover"
        ></Box>
      </Stack>
      <Container
        maxW={`container.xl`}
        py={{ base: 8, lg: 24 }}
        pb={{ base: 12, lg: 36 }}
        ref={exploreRef}
      >
        <Stack
          direction={{ base: "column", lg: `row` }}
          px={{ base: 0, lg: 24 }}
          position={`relative`}
        >
          <AnimeImage
            display={{ base: "none", lg: "block" }}
            image={`https://images.unsplash.com/photo-1504851149312-7a075b496cc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1605&q=80`}
            high={`550px`}
            width={`450px`}
            top={20}
          />
          <AnimeImage
            display={{ base: "block", lg: "none" }}
            image={`https://images.unsplash.com/photo-1504851149312-7a075b496cc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1605&q=80`}
            high={`250px`}
            width={`100%`}
            position={`relative`}
            sm
          />
          <Spacer />
          <Box
            bg={`blackAlpha.100`}
            w={{ base: "100%", lg: `650px` }}
            h={{ base: "100%", lg: `600px` }}
            pt={{ base: 8, lg: 24 }}
            pl={{ base: 6, lg: 32 }}
            pr={{ base: 6, lg: 20 }}
            pb={8}
          >
            <AnimeHeading
              text={`伊豆半島`}
              fontWeight={`normal`}
              fontSize={16}
              fontFamily={`inter`}
            />
            <Stack spacing={4} mb={8}>
              <AnimeText
                text={`この半島で流れるのは" 自然主体 "の特別な時間。`}
                fontSize={12}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`大自然に包まれながら、自然の恵みを堪能する。そこだけにある特別なもの。`}
                fontSize={12}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`目の前に広がる海を眺めてみたり
                満点の星空の中で眠る
                自然と一体になっていくような開放感……`}
                fontSize={12}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`都会から自然へ、非日常を日常に……
                日々のライフスタイルの中で体感できる特別な体験を、あなたならどう楽しむだろうか。`}
                fontSize={12}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`初めてのリゾート暮らしを、私たちサイファームがサポートいたします。`}
                fontSize={12}
                fontFamily={`inter`}
              />
            </Stack>
            <AnimeContainer>
              <AnimatedUnderlineButton
                text={"私たちについて"}
                fontSize={12}
                url={"/about-us"}
              ></AnimatedUnderlineButton>
            </AnimeContainer>
          </Box>
        </Stack>
      </Container>
      <Stack
        py={{ base: 24, lg: 24 }}
        bg={`blackAlpha.400`}
        spacing={{ base: 8, lg: 24 }}
        px={{ base: 4, lg: 24 }}
        w={`100%`}
      >
        <Container maxW={`container.xl`}>
          <AnimatedStay text={`CHOOSE PURPOSE`} fontSize={20} />
          <AnimatedStay text={`目的から選ぶ`} fontSize={24} />
          <AnimatedPurposeImages images={images} />
        </Container>
      </Stack>
      <Stack
        bg={`white`}
        py={{ base: 12, lg: 20 }}
        px={{ base: 0, lg: 20 }}
        align={`center`}
      >
        <AnimatedStay text={`SERVICE`} fontSize={{ base: 14, lg: 20 }} />
        <AnimatedStay
          text={`サービスのご紹介`}
          fontSize={{ base: 15, lg: 24 }}
        />
        <ImageSlidesServices data={services} dark />
      </Stack>
      <Stack
        bg={`blackAlpha.400`}
        py={{ base: 12, lg: 20 }}
        px={{ base: 0, lg: 20 }}
        align={`center`}
      >
        <AnimatedStay text={`PICK UP`} fontSize={{ base: 14, lg: 20 }} />
        <AnimatedStay text={`厳選物件`} fontSize={{ base: 15, lg: 24 }} />
        <ImageSlidesProperties data={properties} />
      </Stack>
      <Stack
        bg={`white`}
        py={{ base: 8, lg: 20 }}
        pb={20}
        px={{ base: 4, lg: 20 }}
        align={`center`}
      >
        <AnimatedStay text={`NEWS`} fontSize={{ base: 14, lg: 20 }} />
        <AnimatedStay text={`ニュース`} fontSize={{ base: 15, lg: 24 }} />
        <Stack
          direction="column"
          w={`100%`}
          align={`center`}
          spacing={{ base: 2, lg: 6 }}
          pt={{ base: 0, lg: 8 }}
        >
          {data.allContentfulNews.edges.map(({ node: news }: any) => {
            return <AnimatedNews data={news} />
          })}
        </Stack>
      </Stack>
    </Layout>
  )
}

export default TopNewPage

export const query = graphql`
  {
    allContentfulSiteImages(
      filter: { location: { eq: "top" } }
      sort: { order: ASC, fields: imageNo }
      limit: 4
    ) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
    allContentfulReviews(limit: 3) {
      edges {
        node {
          avatar {
            file {
              url
            }
          }
          id
          name
          remark {
            remark
          }
        }
      }
    }
    allContentfulNews(
      sort: { order: DESC, fields: tag___updatedAt }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          createdAt(formatString: "YYYY.MM.DD")
          tag {
            name
          }
        }
      }
    }
    allContentfulServices(filter: { published: { eq: true } }, limit: 4) {
      edges {
        node {
          featuredPhoto {
            file {
              url
            }
          }
          name
          slug
          description {
            description
          }
          content {
            raw
          }
        }
      }
    }
    allContentfulProperties(
      filter: { status: { lt: 7 } }
      sort: { order: DESC, fields: updatedAt }
      limit: 4
    ) {
      edges {
        node {
          id
          propertyCode
          propertyName
          slug
          propertyCategory {
            name
          }
          area {
            name
          }
          status
          price
          adress
          nearestStation
          transportation
          layout
          layoutDetail
          structure
          bedroom
          bathroom
          landSpaceSqm
          buildingSpaceSqm
          completion
          landOwnership
          landCategory
          cityPlanningArea
          useZoning
          otherLegalRestriction
          buildingCoverageRatio
          floorAreaRatio
          terrain
          adjacentRoad
          parking
          utilities
          occupancy
          handover
          conditionsTransactions
          images {
            id
            file {
              url
            }
          }
          tags {
            name
          }
          propertyLocation {
            lat
            lon
          }
          comment {
            id
            comment
          }
          onePoint {
            id
            onePoint
          }
          remark {
            id
            remark
          }
          pic
          createdAt
          updatedAt
        }
      }
    }
  }
`
