import React from "react"
import { Box } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"

export const Slide: React.FC<any> = ({ image }) => {
  return (
    <AnimatePresence>
      <Box
        as={motion.img}
        key={image.src}
        src={image.src}
        initial={{ width: 0, opacity: 0 }}
        animate={{ width: `100%`, opacity: 1, transition: { duration: 0.7 } }}
        exit={{ opacity: 0 }}
        h={`450px`}
        objectFit={`cover`}
        top={0}
        left={0}
        position={`absolute`}
        zIndex={50}
      />
    </AnimatePresence>
  )
}
