import React from "react"
import { Box, Button, Stack, Text } from "@chakra-ui/react"
import { motion, useAnimationControls } from "framer-motion"

export const CloseButton: React.FC<any> = ({
  buttonAnimation,
  openButton,
  setDrawer,
}) => {
  const burger1 = useAnimationControls()
  const burger2 = useAnimationControls()
  const burger3 = useAnimationControls()

  return (
    <Button
      as={motion.button}
      animate={buttonAnimation}
      initial={{ x: 100 }}
      whileHover={{
        paddingRight: "10px",
        width: "70px",
        transition: { duration: 0.6 },
      }}
      onHoverStart={() => {
        burger1.start({ x: 7, transition: { duration: 0.6 } })
        burger2.start({ x: 3, transition: { duration: 0.6 } })
        burger3.start({ x: -4, transition: { duration: 0.6 } })
      }}
      onHoverEnd={() => {
        burger1.start({ x: 0 })
        burger2.start({ x: 0 })
        burger3.start({ x: 0 })
      }}
      onClick={() => {
        buttonAnimation.start({ x: 100, transition: { duration: 0.5 } })
        openButton.start({ x: 0, transition: { duration: 0.5 } })
        setDrawer(false)
      }}
      position={`fixed`}
      top={`30%`}
      right={0}
      rounded={0}
      p={0}
      w={{ base: "40px", lg: `60px` }}
      pb={4}
      h={{ base: "100px", lg: `120px` }}
      bg={`white`}
      zIndex={75}
      _hover={{ bg: "white" }}
    >
      <Stack
        direction={`column`}
        justify="center"
        align="center"
        spacing={{ base: 6, lg: 8 }}
      >
        <Stack spacing={{ base: 0, lg: 1 }}>
          <Box
            as={motion.div}
            animate={burger1}
            h={1}
            w={{ base: "18px", lg: `25px` }}
            borderBottom={`1px`}
          ></Box>
          <Box
            as={motion.div}
            animate={burger2}
            h={1}
            w={{ base: "18px", lg: `25px` }}
            borderBottom={`1px`}
          ></Box>
          <Box
            as={motion.div}
            animate={burger3}
            h={1}
            w={{ base: "18px", lg: `25px` }}
            borderBottom={`1px`}
          ></Box>
        </Stack>
        <Text
          transform="rotate(270deg)"
          fontSize={{ base: 10, lg: 12 }}
          color={`blackAlpha.700`}
          letterSpacing={1.8}
        >
          CLOSE
        </Text>
      </Stack>
    </Button>
  )
}
