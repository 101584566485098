import React from "react"
import { Stack } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { AnimatedUnderline } from "../../components/animated-under-line"

export const MenuContent = () => {
  return (
    <Stack
      as={motion.div}
      position={`fixed`}
      top={0}
      right={0}
      initial={{ width: 0 }}
      animate={{ width: "100%", transition: { duration: 0.5 } }}
      exit={{ width: 0, transition: { duration: 0.5 } }}
      bg={`linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.6))`}
      h={`100vh`}
      justifyContent={`center`}
      py={{ base: 0, lg: `12%` }}
      px={24}
      zIndex={75}
      spacing={{ base: 8, lg: 4 }}
      direction={{ base: "column", lg: `row` }}
    >
      <Stack
        as={motion.div}
        initial={`hidden`}
        animate={`visible`}
        variants={{
          hidden: { opacity: 1, scale: 0 },
          visible: {
            opacity: 1,
            scale: 1,
            transition: {
              delayChildren: 0.2,
              staggerChildren: 0.1,
            },
          },
        }}
        w={{ base: "100%", lg: `50%` }}
        spacing={8}
      >
        <AnimatedUnderline text={"HOME"} url={"/"}></AnimatedUnderline>
        <AnimatedUnderline
          text={"私たちについて"}
          url={"/about-us"}
        ></AnimatedUnderline>
        <AnimatedUnderline
          text={"サービスのご紹介"}
          url={"/services"}
        ></AnimatedUnderline>
        <AnimatedUnderline
          text={"会社情報"}
          url={"/company"}
        ></AnimatedUnderline>
        <AnimatedUnderline
          text={"ブログ記事"}
          url={"/articles"}
        ></AnimatedUnderline>
        <AnimatedUnderline
          text={"お問い合わせ"}
          url={"/contact"}
        ></AnimatedUnderline>
      </Stack>
      <Stack
        as={motion.div}
        initial={`hidden`}
        animate={`visible`}
        variants={{
          hidden: { opacity: 1, scale: 0 },
          visible: {
            opacity: 1,
            scale: 1,
            transition: {
              delayChildren: 0.2,
              staggerChildren: 0.1,
            },
          },
        }}
        w={{ base: "100%", lg: `50%` }}
        spacing={8}
      >
        <AnimatedUnderline
          text={"エリアから探す"}
          url={"/map"}
        ></AnimatedUnderline>
        <AnimatedUnderline
          text={"土地を買う"}
          url={"/properties/property-categories/land"}
        ></AnimatedUnderline>
        <AnimatedUnderline
          text={"住まいを買う"}
          url={"/properties/property-categories/house"}
        ></AnimatedUnderline>
        <AnimatedUnderline text={"売る"} url={"/sales"}></AnimatedUnderline>
      </Stack>
    </Stack>
  )
}
