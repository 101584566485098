import React from "react"
import { Flex } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { GoPrimitiveDot } from "react-icons/go"

export const ActiveDot: React.FC<any> = () => {
  return (
    <Flex
      as={motion.div}
      initial={{ width: 0, height: 0 }}
      animate={{
        width: `20px`,
        height: `20px`,
      }}
      exit={{ opacity: 0 }}
      color={`white`}
      border={`1px`}
      rounded={`full`}
      justifyContent={`center`}
      alignItems={`center`}
      p={0}
    >
      <GoPrimitiveDot />
    </Flex>
  )
}
