import React from "react"
import { Stack } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion"
import { ReactNode, useEffect } from "react"
import { useInView } from "react-intersection-observer"

export const AnimeContainer: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 20 },
  }
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible")
    } else {
      control.start("hidden")
    }
  }, [control, inView])

  return (
    <Stack
      as={motion.div}
      direction={`row`}
      variants={boxVariant}
      ref={ref}
      initial={`hidden`}
      animate={control}
    >
      {children}
    </Stack>
  )
}
