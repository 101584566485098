import React from "react"
import { Button, Stack } from "@chakra-ui/react"
import { useInterval } from "usehooks-ts"
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs"
import { useState } from "react"
import { wrap } from "@popmotion/popcorn"
import { Slide } from "./slide"
import { InfoCard } from "./info-card"
import { PaginationButton } from "./pagination-button"
import { SlidesContainer } from "./slide-container"
import { PaginateDot } from "./paginate-dot"

export const ImageSlidesServices: React.FC<any> = ({ data, dark }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const imageIndex = wrap(0, data.length, currentIndex)

  useInterval(() => {
    setCurrentIndex(currentIndex + 1)
  }, 5000)

  const next = () => {
    setCurrentIndex(currentIndex + 1)
  }

  const previous = () => {
    setCurrentIndex(currentIndex - 1)
  }

  return (
    <SlidesContainer>
      <Button
        onClick={next}
        display={{ base: "block", lg: "none" }}
        position={`absolute`}
        zIndex={99}
        w={`20px`}
        p={0}
        h={`450px`}
        bg={`transparent`}
        left={0}
        _active={{ bg: "transparent" }}
      >
        <BsChevronCompactLeft
          size={50}
          style={{
            transform: "scaleY(1.4) scaleX(0.7)",
          }}
        />
      </Button>
      <PaginationButton onClick={previous} dark={dark} right={0}>
        <BsChevronCompactLeft
          size={50}
          style={{
            transform: "scaleY(1.4) scaleX(0.7)",
            marginTop: `80px`,
          }}
        />
      </PaginationButton>
      <Stack
        position={`relative`}
        h={`450px`}
        w={{ base: "100%", lg: `80%` }}
        p={0}
        spacing={0}
      >
        <Slide image={data[imageIndex]} />
        <InfoCard
          title={data[imageIndex].title}
          text={data[imageIndex].text}
          url={data[imageIndex].url}
        />
        <Stack
          position={`absolute`}
          zIndex={75}
          bottom={6}
          left={8}
          direction={`row`}
        >
          {data.map((_: any, index: number) => (
            <PaginateDot
              key={index}
              index={index}
              imageIndex={imageIndex}
              setCurrentIndex={setCurrentIndex}
            />
          ))}
        </Stack>
      </Stack>
      <PaginationButton
        onClick={next}
        pl={{ base: 0, lg: 12 }}
        w={{ base: `30px`, lg: `100px` }}
        dark={dark}
        right={0}
      >
        <BsChevronCompactRight
          size={50}
          style={{
            transform: "scaleY(1.4) scaleX(0.7)",
            marginTop: `80px`,
          }}
        />
      </PaginationButton>
      <Button
        onClick={next}
        display={{ base: "block", lg: "none" }}
        position={`absolute`}
        zIndex={99}
        w={`50px`}
        p={0}
        h={`450px`}
        bg={`transparent`}
        right={0}
        _active={{ bg: "transparent" }}
      >
        <BsChevronCompactRight
          size={50}
          style={{
            transform: "scaleY(1.4) scaleX(0.7)",
          }}
        />
      </Button>
    </SlidesContainer>
  )
}
