import React from "react"
import { Container, Stack } from "@chakra-ui/react"

export const SlidesContainer: React.FC<any> = ({ children }) => {
  return (
    <Container maxW={`container.xl`} p={0}>
      <Stack
        position={`relative`}
        direction="row"
        py={{ base: 12, lg: 20 }}
        px={0}
        spacing={0}
        zIndex={25}
      >
        {children}
      </Stack>
    </Container>
  )
}
