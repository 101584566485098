import React from "react"
import { Flex, forwardRef } from "@chakra-ui/react"
import { motion } from "framer-motion"

export const AnimatedScrollToExplore: React.FC<any> = forwardRef(
  (props, ref: any) => {
    return (
      <Flex justify={`center`}>
        <Flex
          as={motion.div}
          position={`absolute`}
          bottom={-4}
          align="center"
          direction={`column`}
          zIndex={50}
          cursor={`pointer`}
          onClick={() => {
            ref?.current?.scrollIntoView({ behavior: "smooth" })
          }}
          whileHover={{
            y: 15,
            textShadow: "0px 0px 4px gray",
            transition: { duration: 1 },
          }}
        >
          {props.children}
        </Flex>
      </Flex>
    )
  }
)
