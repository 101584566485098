import React from "react"
import { Heading, Stack, Text } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { AnimatedUnderlineButton } from "../../components/animated-under-line-button"

export const InfoCard: React.FC<any> = ({ title, text, url }) => {
  return (
    <Stack
      bg={`linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 253, 0.8))`}
      position="absolute"
      bottom={{ base: 0, lg: -8 }}
      right={{ base: 0, lg: -12 }}
      w={{ base: "100%", lg: `500px` }}
      minH={`250px`}
      py={12}
      px={12}
      zIndex={75}
      spacing={6}
      align={`start`}
      _hover={{ bg: "#f0f0f0", transition: "1.0s" }}
    >
      <AnimatePresence>
        {title && (
          <Heading
            as={motion.h2}
            fontSize={16}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            fontWeight="normal"
            letterSpacing={1.2}
          >
            {title}
          </Heading>
        )}
      </AnimatePresence>

      <Text fontSize={12} lineHeight={1.8}>
        {text}
      </Text>
      <AnimatedUnderlineButton
        text={"詳細を見る"}
        fontSize={12}
        url={url}
      ></AnimatedUnderlineButton>
    </Stack>
  )
}
