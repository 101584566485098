import React from "react"
import { navigate } from "gatsby"
import { Box, Text, Flex } from "@chakra-ui/react"
import { motion } from "framer-motion"

export const AnimatedPurposeImage: React.FC<any> = ({
  image,
  text,
  url,
}) => {
  return (
    <Box
      as={motion.div}
      position={`relative`}
    >
      <Box
        as={motion.img}
        src={image}
        h={`400px`}
        w={`100%`}
        objectFit={`cover`}
        zIndex={50}
        onClick={() =>
          navigate(url)
        }
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
      ></Box>
      <Flex
        position={`absolute`}
        bottom={-6}
        zIndex={75}
        w={`100%`}
        direction={`column`}
        align={`center`}
      >
        <Text color={`white`} fontSize={18} mb={2} fontWeight={`bold`}>
          {text}
        </Text>
        <Box w={1} h={12} borderLeft={`1px`} borderColor={`white`}></Box>
      </Flex>
    </Box>
  )
}
