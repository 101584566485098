import React from "react"
import { Button, Stack } from "@chakra-ui/react"

export const PaginationButton: React.FC<any> = ({
  onClick,
  children,
  pl,
  w,
  dark,
}) => {
  return (
    <Stack display={{ base: "none", lg: "flex" }}>
      <Button
        h={`100%`}
        w={w || `60px`}
        onClick={onClick}
        bg={`transparent`}
        p={0}
        zIndex={99}
        pl={pl || 0}
        color={dark ? "black" : `white`}
        _hover={{ color: dark ? "black" : `white` }}
        _active={{ color: dark ? "black" : `white` }}
      >
        {children}
      </Button>
    </Stack>
  )
}
