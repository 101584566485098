import React from "react"
import { Grid, GridItem } from "@chakra-ui/react"
import { motion, useAnimationControls } from "framer-motion"
import { AnimatedPurposeImage } from "./animated-purpose-image"

export const AnimatedPurposeImages: React.FC<any> = ({ images }) => {
  const frame = useAnimationControls()

  return (
    <Grid
      as={motion.div}
      animate={frame}
      templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(4, 1fr)" }}
      gap={{ base: 0, lg: 6 }}
      mt={{ base: 4, lg: 18 }}
    >
      <GridItem w="100%" h={{ base: "100%", lg: `500px` }} cursor={`pointer`}>
        <AnimatedPurposeImage
          image={images[0].image}
          text={`土地を買う`}
          url={"/properties/property-categories/land"}
        />
      </GridItem>
      <GridItem w="100%" h={{ base: "100%", lg: `500px` }} cursor={`pointer`}>
        <AnimatedPurposeImage
          image={images[1].image}
          text={`住まいを買う`}
          url={"/properties/property-categories/house"}
        />
      </GridItem>
      <GridItem w="100%" h={{ base: "100%", lg: `500px` }} cursor={`pointer`}>
        <AnimatedPurposeImage
          image={images[2].image}
          text={`エリアから探す`}
          url={"/map"}
        />
      </GridItem>
      <GridItem w="100%" h={{ base: "100%", lg: `500px` }} cursor={`pointer`}>
        <AnimatedPurposeImage
          image={images[3].image}
          text={`不動産の売却`}
          url={"/sales"}
        />
      </GridItem>
    </Grid>
  )
}
