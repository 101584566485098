import React from "react"
import { Flex } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { GoPrimitiveDot } from "react-icons/go"

export const ClickableDot: React.FC<any> = ({ setCurrentIndex, index }) => {
  return (
    <Flex
      as={motion.div}
      exit={{ opacity: 0 }}
      color={`white`}
      border={`1px`}
      rounded={`full`}
      cursor={`pointer`}
      justifyContent={`center`}
      alignItems={`center`}
      onClick={() => setCurrentIndex(index)}
      whileHover={{
        width: `20px`,
        height: `20px`,
        transition: { duration: 0.2 },
      }}
      h={2}
      w={2}
      p={0}
    >
      <GoPrimitiveDot />
    </Flex>
  )
}
