import React from "react"
import { Stack, Text, Box } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"

export const AnimatedNews: React.FC<{ data: any }> = ({ data }) => {
  const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 50 },
  }
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible")
    } else {
      control.start("hidden")
    }
  }, [control, inView])

  return (
    <Stack direction="column" w={`100%`} align={`center`} spacing={6} pt={8}>
      <Stack
        direction="row"
        w={{ base: "100%", lg: `80%` }}
        align="center"
        justify={`start`}
        spacing={{ base: 4, lg: 8 }}
        as={motion.p}
        variants={boxVariant}
        ref={ref}
        initial={`hidden`}
        animate={control}
      >
        <Text fontSize={{ base: 10, lg: 14 }}>
          {data.createdAt}
        </Text>
        <Box
          bg={`black`}
          p={2}
          w={{ base: "100px", lg: "120px" }}
          flexShrink={0}
        >
          <Text fontSize={{ base: 10, lg: 14 }} color={`white`}>
            {data.tag.name}
          </Text>
        </Box>
        <Text fontSize={{ base: 10, lg: 14 }} fontWeight="bold">
          {data.title}
        </Text>
      </Stack>
  </Stack>
  )
}
